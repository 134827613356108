import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { CaretLeft } from 'phosphor-react'
import Seo from '../components/Seo'

const BlogPostTemplate = ({
  content,
  categories,
  tags,
  title,
  date,
  featuredImage,
}) => {
  return (
    <section className="section">
      <img
        src={featuredImage?.node.mediaItemUrl}
        className="w-full object-cover"
        style={{ height: '400px' }}
      />
      <div class="p-8 md:p-20">
        <Link to="/blog/" className="text-brand-blue mb-8 block">
          <span className="flex gap-1 items-center">
            <CaretLeft size={20} /> Blog
          </span>
        </Link>
        <h1 className="text-center text-brand-blue font-thin uppercase text-3xl md:text-5xl">
          {title}
        </h1>
        <p className="text-center">{date}</p>
        <div className="border-b border-brand-blue w-full mt-4 md:mt-16" />
      </div>

      <div
        className="blog-content px-8 md:px-20 pb-10 max-w-screen-xl mx-auto"
        dangerouslySetInnerHTML={{ __html: content }}
      />

      <div className="w-full items-center justify-center px-8 md:px-20 pb-10 max-w-screen-xl mx-auto flex">
        <Link to="/blog/" className="block btn">
          Back to blog
        </Link>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data, pageContext }) => {
  const { wpPost: post } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={post.seo} />
      <Helmet>
        <script type="application/ld+json">
          {`[{ "@context": "https://schema.org", "@type": "BlogPosting", "@id": "https://www.dmautoleasing.com/blog${post.uri}#BlogPosting", "url": "https://www.dmautoleasing.com/blog${post.uri}", "mainEntityOfPage": "D&amp;M Auto Leasing",{
          "@context": "https://schema.org","@type": "Review", "@id": "https://www.dmautoleasing.com/#Review",  "itemReviewed": {"@type": "LocalBusiness", "image": []},"name": "D&M Auto Leasing", "author": {"@type": "Person","name": "${post.author?.node?.name}"
          }
        }]`}
        </script>
      </Helmet>
      <BlogPostTemplate
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
        featuredImage={post.featuredImage}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      uri
      seo {
        fullHead
        schema {
          raw
        }
      }
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
    }
  }
`
